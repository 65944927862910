import styled from '@emotion/styled'
import React from 'react'
import InViewMonitor from 'react-inview-monitor'

import useLocalisation from '../../helper/useLocalisation'
import dream4 from '../../images/2021-fathers-day/1.svg'
import Handle from '../../images/carryonnew/handle.svg'
import Laundry from '../../images/carryonnew/laundry.svg'
import Strap from '../../images/carryonnew/strap.svg'
import handle from '../../images/category/handle.svg'
import dream1 from '../../images/dreamscapes/1.svg'
import dream2 from '../../images/dreamscapes/2.svg'
import dream3 from '../../images/dreamscapes/3.svg'

const FadeUp = ({ children, delay }) => {
  let delayClass = ''
  if (delay !== undefined) {
    delayClass = `delay-c-${delay}s`
  }
  return (
    <InViewMonitor
      classNameNotInView="vis-hidden"
      classNameInView={`animate__animated animate__fadeInUp ${delayClass}`}
    >
      {children}
    </InViewMonitor>
  )
}

const Fade = ({ children, delay }) => {
  let delayClass = ''
  if (delay !== undefined) {
    delayClass = `delay-c-${delay}s`
  }
  return (
    <InViewMonitor
      classNameNotInView="vis-hidden"
      classNameInView={`animate__animated animate__fadeIn ${delayClass}`}
    >
      {children}
    </InViewMonitor>
  )
}

// Details
const DetailsContainer = styled.div`
  border: solid 1px #cecece;
  padding: 64px 0;
  ${(props) => (props.color ? `background: ${props.color}` : '')}
`

const DetailsHeader = styled.h2`
  font-size: 38px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -1.11px;
  text-align: center;
  color: rgb(var(--dark-blue-grey));
  padding: 32px 0;
`

const DetailSectionsContaniner = styled.div`
  height: calc(100% - 200px);
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 0 64px;
  overflow: hidden;
  max-width: 1100px;
  margin: 0 auto;
  @media (max-width: 763px) {
    flex-wrap: wrap;
    height: auto;
    padding: 0 16px;
  }
`

const DetailSectionImage = styled.img`
  width: 70%;
  margin: 0 15%;
`
const DetailSectionImageContainer = styled.div`
  width: 70%;
  margin: 0 15%;
  @media (max-width: 763px) {
    width: 90%;
    margin: 0 auto;
  }
  @media (min-width: 762px) {
    display: flex;
    align-items: flex-end;
    margin-bottom: 16px;
  }
`
const DetailSectionHeading = styled.div`
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.71px;
  text-align: center;
  color: #282828;
  padding: 16px 0;
  min-height: calc(3.25em * 2);
  @media (min-width: 762px) {
    min-height: calc(2.25em * 2);
  }
`
const DetailSectionBody = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 0.08px;
  text-align: center;
  color: rgb(var(--black));
  padding-bottom: 48px;
`

const DetailSectionContainer = styled.div`
  padding: 0 32px;
  width: 25%;
  @media (max-width: 763px) {
    padding: 0px;
    width: calc(50% - 32px);
  }
`

const ReversableDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 763px) {
    ${(props) =>
      props.reverse
        ? 'flex-direction: column-reverse;'
        : 'flex-direction: column;'}
  }
`

const DetailSection = ({ data }) => {
  return data.map((section, key) => {
    return (
      <DetailSectionContainer key={key}>
        <FadeUp delay={key}>
          <ReversableDiv reverse={key > 1}>
            <DetailSectionHeading> {section.title} </DetailSectionHeading>
            <DetailSectionImageContainer>
              <DetailSectionImage src={section.image} />
            </DetailSectionImageContainer>
          </ReversableDiv>
        </FadeUp>
      </DetailSectionContainer>
    )
  })
}

const Mobile = styled.span`
  @media (min-width: 764px) {
    display: none;
  }
`

const DetailsSection = ({ data, bags, color }) => {
  const Q = useLocalisation()
  if (bags) {
    return (
      <DetailsContainer color={color}>
        <DetailSectionsContaniner>
          <DetailSection
            data={[
              {
                image: dream1,
                title: (
                  <>
                    <br />
                    100 DAY RETURNS
                  </>
                ),
                copy: (
                  <>
                    Every case features a hidden laundry bag that is{' '}
                    {Q('odour')}-proof and compresses down to keep your worn
                    items hidden and the rest of your clothes crisp clean.
                  </>
                ),
              },
              {
                title: (
                  <>
                    <br />
                    FREE SHIPPING
                  </>
                ),
                image: dream3,
                copy: "We've made it easy to pack more into your trip. Our July Y-Strap Compression System provides a heavy-duty strap that pulls down to create more space in your case. ",
              },
              {
                title: (
                  <>
                    5 YEAR <br /> WARRANTY
                  </>
                ),
                image: dream2,
                copy: 'Our multi-stop telescopic handle stops wherever you want it to. With a soft-to-touch rubber handle making it a pleasure to hold.',
              },
              {
                title: (
                  <>
                    SECURES TO <br /> YOUR LUGGAGE{' '}
                  </>
                ),
                image: handle,
                copy: "We've made it easy to pack more into your trip. Our July Y-Strap Compression System provides a heavy-duty strap that pulls down to create more space in your case. ",
              },
            ]}
          />
        </DetailSectionsContaniner>
      </DetailsContainer>
    )
  }
  return (
    <DetailsContainer color={color}>
      <DetailSectionsContaniner>
        <DetailSection
          data={[
            {
              title: (
                <>
                  <br />
                  FAST DELIVERY
                </>
              ),
              image: dream3,
              copy: "We've made it easy to pack more into your trip. Our July Y-Strap Compression System provides a heavy-duty strap that pulls down to create more space in your case. ",
            },
            {
              title: (
                <>
                  <br />
                  CORPORATE PRICING
                </>
              ),
              image: dream2,
              copy: 'Our multi-stop telescopic handle stops wherever you want it to. With a soft-to-touch rubber handle making it a pleasure to hold.',
            },
            {
              title: (
                <>
                  {Q('Personalise', {
                    textTransform: 'uppercase',
                  })}
                  D <br />
                  PRODUCTS
                </>
              ),
              image: dream4,
              copy: "We've made it easy to pack more into your trip. Our July Y-Strap Compression System provides a heavy-duty strap that pulls down to create more space in your case. ",
            },
          ]}
        />
      </DetailSectionsContaniner>
    </DetailsContainer>
  )
}

export default DetailsSection
