/**
 * POST feedback data to Zendesk
 * https://developer.zendesk.com/rest_api/docs/core/requests#create-request
 *
 * @param {Object} data      - Feedback data payload
 * @param {String} subdomain - Subdomain of zendesk account eg https://${subdomain}.zendesk.com
 *
 * @return {Object}          - Response from Zendesk
 *
 */
export const sendFeedback = async (data, subdomain) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }
    const response = await fetch(
      `https://1ovslj9sg3.execute-api.ap-southeast-2.amazonaws.com/gorgias_frontend_form`,
      {
        mode: 'no-cors',
        method: 'POST',
        body: JSON.stringify(data),
        headers,
      }
    )
    const responseData = await response
    return responseData
  } catch (error) {
    console.log(error)
    return error
  }
}

/**
 * Format a payload of form data to the format required by Zendesk
 *
 * @param {String} subject  - Zendesk ticket subject
 * @param {String} email    - Email provided by user giving feedback
 * @param {String} feedback - Feedback provided by user
 * @param {String} pageURL  - URL of page user submitted feedback from
 *
 * @return {Object}         - Payload for Zendesk Requests API
 *
 */
export const formatPayload = (subject, email, defaultName, feedback, tags) => {
  return {
    email: email,
    name: defaultName,
    subject: subject,
    message: feedback,
    tags: tags,
  }
}
